import { Component, Injector, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    ComponentDto,
    ComponentsServiceProxy, GetDowntimeForViewDto,
    IotDataResponse,
    PowerPlantDto,
    PowerPlantType,
    StatusCode,
    UnitStatusNotificationServiceProxy,
    WindUnitModelStatusDto,
    WindUnitStatus
} from "@shared/service-proxies/service-proxies";
import { Store } from "@node_modules/@ngrx/store";
import { Observable } from "@node_modules/rxjs";
import { BaseComponentConsts } from "@app/main/report-trials/WidgetConsts";
import * as _ from "lodash";
import { MapService } from "@app/shared/services/map.service";
import { MapConstants, UnitNotificationClasses, UnitStatusClasses, UnitStatusText } from "@app/main/map/map.const";
import { AssetDetail } from "@app/main/map/map.model";
import * as moment from "moment";
import { Router } from "@angular/router";
import { setMapLocation } from "@app/state-management/actions/map.action";

@Component({
    selector: "app-plant-detail-drawer",
    templateUrl: "./plant-detail-drawer.component.html",
    styleUrls: ["./plant-detail-drawer.components.css"]
})
export class PlantDetailDrawerComponent extends AppComponentBase implements OnInit {
    private allWindUnitStatus: WindUnitModelStatusDto[];
    private tenancyName: string;
    public KwhToMwh = 1000;
    public statusCodes: any[] = [];
    private iotDataValues: IotDataResponse;
    mapReducer: Observable<string>;
    isDrawerOpen: boolean = false;
    assetDetail: AssetDetail;
    unitComponents: ComponentDto[];
    currentProduction: string = "...";
    currentWindSpeed: string = "...";
    currentWaterLevel: string = "...";
    private powerPlants: PowerPlantDto[];
    unitStatusNotificationRecent: GetDowntimeForViewDto[];
    protected readonly PowerPlantType = PowerPlantType;
    protected readonly moment = moment;
    private readonly LODOS_TENANT_NAME = "lodos";
    powerPlantStatusIconClassName: string = "";

    constructor(
        injector: Injector,
        private store: Store,
        private _componentService: ComponentsServiceProxy,
        private _unitStatusNotificationService: UnitStatusNotificationServiceProxy,
        private _mapService: MapService,
        private _router: Router
    ) {
        super(injector);
    }

    async ngOnInit() {
        await this._mapService.getLocalStorageItemAsync(
            MapConstants.LocalStorageKeys.TenancyName,
            (cache) => (this.tenancyName = cache)
        );
        await this._mapService.getLocalStorageItemAsync(
            MapConstants.LocalStorageKeys.PowerPlantList,
            (cache) => (this.powerPlants = cache)
        );
        await this._mapService.getLocalStorageItemAsync(
            MapConstants.LocalStorageKeys.ComponentList,
            (cache) => (this.unitComponents = cache)
        );
        await this._mapService.getLocalStorageItemAsync(
            MapConstants.LocalStorageKeys.AllWindUnitStatus,
            (cache) => (this.allWindUnitStatus = cache)
        );

        this.store
            .select((state) => state)
            .subscribe((state: any) => {
                this.isDrawerOpen = state.mapReducer.data.isDetailDrawerOpen;
                this.assetDetail = state.mapReducer.data.assetDetail;
                this.setAssetStatus();
                this.getProduction(this.iotDataValues);
                this.getGetWindSpeed(this.iotDataValues);
                this.getWaterLevel(this.iotDataValues);
                this.getUnitStatusNotifications();
            });

        this.subscribeToEvent("iot-data-all-schema", (realtimeIotData: IotDataResponse) => {
            this.iotDataValues = realtimeIotData;
            this.getProduction(realtimeIotData);
            this.getGetWindSpeed(realtimeIotData);
            this.getWaterLevel(realtimeIotData);
        });

        this.subscribeToEvent("iot-status-all-schema", async (statusCodesRespone: StatusCode[]) => {
            this.statusCodes = statusCodesRespone;
            this.setAssetStatus();
        });

        this.subscribeToEvent("changed-unit-status-notification", (statusNotification) => {
            this.getUnitStatusNotifications();
        });
        this.subscribeToEvent("plant-marker-color-changed", (plantMarkerStatusChangeEvent) => {
            if (_.isNil(this.assetDetail) || _.isNil(plantMarkerStatusChangeEvent)) {
                return;
            }

            if (plantMarkerStatusChangeEvent.powerPlant.id != this.assetDetail.powerPlantId) {
                return;
            }

            const className =
                UnitStatusClasses[plantMarkerStatusChangeEvent.powerPlant.type][plantMarkerStatusChangeEvent.onePactResponse];
            if (!_.isNil(className)) {
                this.powerPlantStatusIconClassName = className;
            }

        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.store.dispatch(
            setMapLocation({
                isDetailDrawerOpen: false,
                assetDetail: null
            })
        );
    }

    setAssetStatus() {
        if (!_.isNil(this.assetDetail) && !_.isNil(this.assetDetail.unitId) && !this.assetDetail.isPowerPlant) {
            let filterStatusComponent: ComponentDto;
            // for solar inverter
            if (!_.isNil(this.assetDetail.solarInverterId)) {
                filterStatusComponent = this.unitComponents.find(
                    (x) =>
                        x.baseComponentId == BaseComponentConsts.Status &&
                        x.solarInverterId == Number(this.assetDetail.solarInverterId)
                );
            } else {
                filterStatusComponent = this.unitComponents.find(
                    (x) =>
                        x.baseComponentId == BaseComponentConsts.Status && x.powerPlantUnitId == Number(this.assetDetail.unitId)
                );
            }

            const filterBaseComponent = this.statusCodes.find((elem) => elem.uuid == filterStatusComponent.opcItemId.toString());

            let powerPlant = this.powerPlants.find((x) => x.id == this.assetDetail.powerPlantId);
            let status = this.allWindUnitStatus.find(
                (x) => x.mainStatus == filterBaseComponent.value && x.unitModelId == this.assetDetail.unitModelId
            );

            this.assetDetail = {
                ...this.assetDetail,
                statusText: UnitStatusText[powerPlant.type][status.onePactResponse],
                mainStatus: status.mainStatus
            };
        }
    }

    getProduction(data: IotDataResponse) {
        if (!_.isNil(this.assetDetail) && !_.isNil(this.assetDetail.unitId)) {
            let filteredUnitComponents;
            if (this.assetDetail.isPowerPlant) {
                filteredUnitComponents = this._mapService.getPowerPlantFilteredUnitComponents(
                    this.assetDetail.powerPlantDto,
                    this.unitComponents
                );
            } else {
                if (this.assetDetail.powerPlantType === PowerPlantType.Solar) {
                    filteredUnitComponents = this._mapService.getUnitFilteredUnitComponentsForSolarInverter(
                        this.assetDetail.solarInverterId,
                        this.unitComponents
                    );
                } else {
                    filteredUnitComponents = this._mapService.getUnitFilteredUnitComponents(
                        this.assetDetail.unitId,
                        this.unitComponents
                    );
                }
            }

            if (!_.isNil(filteredUnitComponents)) {
                const powerValues = this._mapService.getProductionValueByPowerPlantType(
                    this.assetDetail.powerPlantDto,
                    data,
                    filteredUnitComponents,
                    this.tenancyName
                );

                const sumValue = powerValues / this.KwhToMwh;
                this.currentProduction = _.round(sumValue, 2).toFixed(2);
                if (this.currentProduction == "0.00") {
                    this.powerPlantStatusIconClassName = this.getClassNameForStatusIcon(
                        WindUnitStatus.Warning.toString(),
                        this.assetDetail.unitModelId
                    );
                }
            }
        }
    }

    getUnitComponents() {
        return new Promise((resolve, reject) => {
            this._componentService.getAllList().subscribe((result) => {
                this.unitComponents = result;
                resolve(result);
            });
        });
    }

    getGetWindSpeed(data: IotDataResponse) {
        // calculate for power plant wind speed
        if (
            !_.isNil(this.assetDetail) &&
            !_.isNil(this.assetDetail.unitId) &&
            this.assetDetail.unitId == 0 &&
            this.assetDetail.powerPlantType === PowerPlantType.Wind
        ) {
            let filteredUnitComponents = this.unitComponents.filter(
                (x) =>
                    x.baseComponentId == BaseComponentConsts.WindSpeed &&
                    x.powerPlantId === Number(this.assetDetail.powerPlantId) &&
                    !_.isNil(x.powerPlantUnitId)
            );

            if (!_.isNil(filteredUnitComponents) && !_.isNil(data)) {
                let windSpeed = data.values.filter((x) =>
                    this.tenancyName == this.LODOS_TENANT_NAME
                        ? filteredUnitComponents.find((y) => y.uuid == x.uuid.toString())
                        : filteredUnitComponents.find((y) => y.opcItemId.toString() == x.uuid)
                );
                let sumValue = _.meanBy(windSpeed, (p) => p.value);
                this.currentWindSpeed = _.round(sumValue, 2).toFixed(2);
            }
        }
        // calculate for unit wind speed
        else if (
            !_.isNil(this.assetDetail) &&
            !_.isNil(this.assetDetail.unitId) &&
            this.assetDetail.unitId != 0 &&
            this.assetDetail.powerPlantType === PowerPlantType.Wind
        ) {
            let filteredUnitComponents = this.unitComponents.filter(
                (x) =>
                    x.baseComponentId == BaseComponentConsts.WindSpeed && x.powerPlantUnitId === Number(this.assetDetail.unitId)
            );

            if (!_.isNil(filteredUnitComponents) && !_.isNil(data)) {
                let windSpeed = data.values.find((x) =>
                    this.tenancyName == this.LODOS_TENANT_NAME
                        ? filteredUnitComponents.find((y) => y.uuid == x.uuid.toString())
                        : filteredUnitComponents.find((y) => y.opcItemId.toString() == x.uuid)
                );
                let sumValue = windSpeed.value;
                this.currentWindSpeed = _.round(sumValue, 2).toFixed(2);
            }
        }
    }

    getUnitStatusNotifications() {
        if (this.assetDetail) {
            // for power plant status
            if (this.assetDetail.isPowerPlant) {
                this._unitStatusNotificationService
                    .getNotificationByAssetId(undefined, this.assetDetail.powerPlantId, 8)
                    .subscribe((data) => {
                        this.initializeDrawerData(data);
                    });
            }
            // for unit status
            else {
                this._unitStatusNotificationService
                    .getNotificationByAssetId(this.assetDetail.unitId, undefined, 4)
                    .subscribe((data) => {
                        this.initializeDrawerData(data);
                    });
            }
        }
    }

    initializeDrawerData(data: GetDowntimeForViewDto[]) {
        this.unitStatusNotificationRecent = data;
    }

    public closeDrawer() {
        this.isDrawerOpen = false;
    }

    getClassNameForStatusText(mainStatus: string, unitModelId: number) {
        return this._mapService.getClassNameForStatusText(
            mainStatus,
            this.allWindUnitStatus,
            this.assetDetail.powerPlantType,
            unitModelId
        );
    }

    getClassNameForStatusTextContent(mainStatus: string, unitModelId: number) {
        return this._mapService.getClassNameForStatusTextContent(
            mainStatus,
            this.allWindUnitStatus,
            this.assetDetail.powerPlantType,
            unitModelId
        );
    }

    getClassNameForStatusIcon(mainStatus: string, unitModelId: number) {
        return this._mapService.getClassNameForStatusIcon(
            mainStatus,
            this.allWindUnitStatus,
            this.assetDetail.powerPlantType,
            unitModelId
        );
    }

    getSearchIcon(isPowerPlant: boolean) {
        if (isPowerPlant) {
            return "ic_tabler_building-company";
        } else {
            return "ic_tabler_building-wind-turbine-main";
        }
    }

    getUnitStatusIconColor(notification: GetDowntimeForViewDto) {
        const res = UnitNotificationClasses[notification.powerPlantType][notification.onePactResponse];
        if (_.isNil(res)) {
            return "x-4";
        }
        return res;
    }

    getDateForDrawer(startDate: any) {
        return moment(new Date(startDate.toString()).toLocaleString("en-US", { timeZone: this.localTimeZone })).format(
            "DD MMM, YYYY HH:mm"
        );
    }

    navigateNotifications() {
        this._router.navigate([`app/main/unit-status-notification`]);
    }

    getUnitIcon() {
        const plantType = PowerPlantType[this.assetDetail.powerPlantType].toLowerCase();
        const markerImagePath = "/assets/common/images/map/plant-marker/";
        let style = `background-image: url(${markerImagePath}building-community-${plantType}.svg)`;
        return style;
    }

    private getWaterLevel(realtimeIotData: IotDataResponse) {
        if (
            !_.isNil(realtimeIotData) &&
            !_.isNil(this.assetDetail) &&
            !_.isNil(this.assetDetail.powerPlantId) &&
            this.assetDetail.powerPlantType === PowerPlantType.Hydro
        ) {
            let unitComponent = this.unitComponents.find(
                (x) =>
                    x.baseComponentId == BaseComponentConsts.WaterLevel &&
                    x.powerPlantId === Number(this.assetDetail.powerPlantId)
            );

            if (!_.isNil(unitComponent)) {
                const { uuid, opcItemId } = unitComponent;
                let waterLevel = realtimeIotData.values.find((x) =>
                    this.tenancyName == this.LODOS_TENANT_NAME ? x.uuid == uuid.toString() : x.uuid == opcItemId.toString()
                );

                if (waterLevel) {
                    this.currentWaterLevel = _.round(waterLevel.value, 2).toFixed(2);
                }
            }
        }
    }

    getClassNameForIconContent(mainStatus: string, unitModelId: number) {
        let color = this._mapService.getClassNameForStatusIcon(
            mainStatus,
            this.allWindUnitStatus,
            this.assetDetail.powerPlantType,
            unitModelId
        );

        if (!_.isNil(this.powerPlantStatusIconClassName))
            return this.powerPlantStatusIconClassName;
        else
            return color;


    }
}
