import {Component, EventEmitter, Injector, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ModalDirective} from "@node_modules/ngx-bootstrap/modal";
import {
    EditWidgetConfig, PowerPlantType,
    ReportDashboardDto,
    ReportDashboardServiceProxy
} from "@shared/service-proxies/service-proxies";
import {AppComponentBase} from "@shared/common/app-component-base";
import * as ReportTrialsUtilities from "@app/main/report-trials/utilities";

@Component({
    selector: 'saveWidgetModal',
    templateUrl: './save-widget.component.html',
    styleUrls: ['./save-widget.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SaveWidgetComponent extends AppComponentBase {
    @ViewChild('createOrEditModal', {static: true}) modal: ModalDirective;
    @Output() updateDashboard: EventEmitter<any> = new EventEmitter<any>();
    @Input() pageName = "";
    dashboard: ReportDashboardDto;
    saving = false;
    widgetName = "";
    editWidgetConfig: EditWidgetConfig;
    powerPlantType: PowerPlantType
    isUnit: boolean=false;

    constructor(
        injector: Injector,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
    ) {
        super(injector);
    }

    show(item: ReportDashboardDto, powerPlantType: PowerPlantType, isUnit: boolean, editWidgetConfig?: EditWidgetConfig): void {
        this.powerPlantType = powerPlantType;
        this.widgetName = item.name;
        this.editWidgetConfig = editWidgetConfig;
        this.isUnit = isUnit;
        this.dashboard = item;
        this.saving = false;
        this.modal.show();
    }

    close(): void {
        this.widgetName = "";
        this.saving = true;
        this.isUnit = false;
        this.modal.hide();
    }

    saveAsWidget() {
        this.message.confirm("", this.l("AreYouSure"), (isConfirmed) => {
            if (isConfirmed) {
                this.editWidgetConfig.general.name = this.widgetName;
                this.editWidgetConfig.general.description = this.widgetName;
                this._reportDashboardServiceProxy
                    .saveWidgetInfo(1003, this.powerPlantType,this.isUnit,0,0, this.editWidgetConfig)
                    .subscribe(() => {
                        this.widgetName = "";
                        this.notify.info(this.l('SavedSuccessfully'));
                        this.saving = true;
                        this.isUnit = false;
                        // this.updateDashboard.emit(this.dashboard);
                        this.modal.hide();
                    });
            }
        });


    }

}
