import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from "abp-ng2-module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import * as ApiServiceProxies from "./service-proxies";
import { ZeroRefreshTokenService } from "@account/auth/zero-refresh-token.service";
import { ZeroTemplateHttpConfigurationService } from "./zero-template-http-configuration.service";

@NgModule({
    providers: [
        ApiServiceProxies.SolarStringsServiceProxy,
        ApiServiceProxies.SolarInvertersServiceProxy,
        ApiServiceProxies.MeasurementUnitsServiceProxy,
        ApiServiceProxies.ReportsServiceProxy,
        ApiServiceProxies.OnePactAiIntegratorServiceProxy,
        ApiServiceProxies.DowntimesServiceProxy,
        ApiServiceProxies.ModelPredictionsServiceProxy,
        ApiServiceProxies.PiConnectorServiceProxy,
        ApiServiceProxies.ModelRunServiceProxy,
        ApiServiceProxies.ModelDeploymentsServiceProxy,
        ApiServiceProxies.ModelAlgorithmsServiceProxy,
        ApiServiceProxies.PreprocessingsServiceProxy,
        ApiServiceProxies.PreprocessingServiceProxy,
        ApiServiceProxies.DataSetsServiceProxy,
        ApiServiceProxies.ComponentsServiceProxy,
        ApiServiceProxies.PowerPlantUnitsServiceProxy,
        ApiServiceProxies.PowerPlantsServiceProxy,
        ApiServiceProxies.WindModelUnitStatusServiceProxy,
        ApiServiceProxies.CompaniesServiceProxy,
        ApiServiceProxies.BaseComponentsServiceProxy,
        ApiServiceProxies.AggregationsServiceProxy,
        ApiServiceProxies.RegionsServiceProxy,
        ApiServiceProxies.CountriesServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        ApiServiceProxies.IotDataServiceProxy,
        ApiServiceProxies.SectionsServiceProxy,
        ApiServiceProxies.ReportDashboardServiceProxy,
        ApiServiceProxies.OwnersServiceProxy,
        ApiServiceProxies.ManufacturersServiceProxy,
        ApiServiceProxies.ModelsServiceProxy,
        ApiServiceProxies.ExploreServiceProxy,
        ApiServiceProxies.PortfolioComponentServiceProxy,
        ApiServiceProxies.UnitStatusNotificationServiceProxy,
        ApiServiceProxies.GenerationForecastServiceProxy,
        ApiServiceProxies.AlertManagementServiceProxy,
        ApiServiceProxies.HydroReportAppDashboardServiceProxy,
        ApiServiceProxies.WindReportAppDashboardServiceProxy,
        ApiServiceProxies.SolarReportAppDashboardServiceProxy,
        ApiServiceProxies.EquipmentServiceProxy,
        ApiServiceProxies.ElectroMeterServiceProxy,
        ApiServiceProxies.SwitchyardOperatingServiceProxy,
        ApiServiceProxies.TaskManagementServiceProxy,
        ApiServiceProxies.ModelDeploymentAlertMetricsServiceProxy,
        ApiServiceProxies.AlertsServiceProxy,
        ApiServiceProxies.AlertDefinitionsServiceProxy,
        ApiServiceProxies.AlertDashboardServiceProxy,
        ApiServiceProxies.PowerPlantBudgetServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
